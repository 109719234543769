import { Component, Watch } from 'vue-property-decorator';
import { OsQueryPlan, OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { ProjectProductList, ProjectProductListQuery, ProjectResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { dateFormat, debounce, downloadFileByBlob, getFileExtension, messageError, translation } from '@/utils';
import { billingService, customerService, minioService, projectProductService } from '@/api';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import {
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  ProjectItemTypeEnum,
  ProjectProductStatusEnum,
  PageQueryPlanEnum,
  CloseStatusEnum,
  ArchiveStatusEnum
} from '@/resource/enum';
import { ImportFile } from '@/views/dialogs';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { SortOptions } from '@/api/base';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { NormalSelectOptions } from '@/resource/model/common';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import saleDetailsService from '@/api/project-management/sale-details';

import ProjectProductDetails from '../project/project-details/project-product/project-product-details/project-product-details.vue';

@Component({
  name: 'SaleDetails',
  components: {
    ImportFile,
    ElImageViewer,
    ProjectProductDetails
  }
})
export default class SaleDetails extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.saledetails;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];
  public tableOption: OsTableOption<ProjectProductList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'itemCode', order: 'ascending' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<
    OsTableColumn<
      ProjectProductList & {
        deliveryCount: number;
        createUserName: string;
        invoiceCount: number;
        projectName: string;
        customerName: string;
        createTime: string;
      }
    >
  > = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '230px',
      showOverflowTooltip: true,
      fixed: true,
      sortable: 'custom'
    },
    {
      prop: 'projectName',
      label: 'billing.ownerProject',
      minWidth: '230px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customerName',
      label: 'order.customer',
      minWidth: '230px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressStatus',
      label: 'projectProduct.prepressStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressStatus) {
          return translation('common.unKnownStatus');
        }
        return translation(`prepressStatus.${PrepressStatusEnum[(row as ProjectProductList).prepressStatus]}`);
      }
    },
    {
      prop: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return translation(`archiveStatus.${ArchiveStatusEnum[(row as ProjectProductList).archiveStatus]}`);
      }
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '250px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'count',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).visibleWidth} × ${(row as ProjectProductList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList).finishWidth} × ${(row as ProjectProductList).finishHeight}`;
      }
    },
    {
      prop: 'prepressPicture',
      label: 'projectProduct.prepressPicture',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'projectProduct.prepressRemark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCrafts',
      label: 'projectProduct.prepressCrafts',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (!(row as ProjectProductList).prepressCrafts) {
          return '--';
        }
        if ((row as ProjectProductList).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
          );
        }
        return translation(
          `prepressLayoutCrafts.${PrepressLayoutCraftsEnum[(row as ProjectProductList).prepressCrafts]}`
        );
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'makeStatus',
    //   label: 'projectProduct.status',
    //   minWidth: '130px',
    //   showOverflowTooltip: true,
    //   formatter: (row: Object): string => {
    //     if (!(row as ProjectProductList).makeStatus) {
    //       return translation('common.unKnownStatus');
    //     }
    //     return translation(`projectProductStatus.${ProjectProductStatusEnum[(row as ProjectProductList).makeStatus]}`);
    //   }
    // },
    {
      prop: 'itemType',
      label: 'projectProduct.itemType',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        const itemType = (row as ProjectProductList).itemType;
        return translation(`projectItemType.${ProjectItemTypeEnum[itemType]}`);
      }
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'projectProduct.requiredDeliveryTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredDeliveryTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'requiredArriveTime',
      label: 'projectProduct.requiredArriveTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList).requiredArriveTime, 'YYYY-MM-DD HH:mm');
      }
    },
    {
      prop: 'relationCount',
      label: 'projectProduct.relationCount',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList)!.relationFinishCount ?? 0}/ ${(row as ProjectProductList)!
          .relationCount ?? 0}`;
      }
    },
    {
      prop: 'deliveryCount',
      label: 'projectProduct.deliveryCount',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProjectProductList)!.logisticsFlag === 1) {
          return `${(row as ProjectProductList)!.deliveryFinishCount ?? 0} / ${(row as ProjectProductList)!
            .deliveryCount ?? 0}`;
        }
        return translation('prepress.unopened');
      }
    },
    {
      prop: 'installCount',
      label: 'projectProduct.installCount',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProjectProductList)!.installFlag === 1) {
          return `${(row as ProjectProductList)!.installFinishCount ?? 0} / ${(row as ProjectProductList)!
            .installCount ?? 0}`;
        }
        return translation('prepress.unopened');
      }
    },
    {
      prop: 'invoiceCount',
      label: 'projectProduct.invoiceCount',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return `${(row as ProjectProductList)!.invoiceFinishCount ?? 0} / ${(row as ProjectProductList)!.invoiceCount ??
          0}`;
      }
    },
    {
      prop: 'closeStatus',
      label: 'projectProduct.closeStatus',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).closeStatus === CloseStatusEnum.closed
          ? translation('closeStatus.closed')
          : translation('closeStatus.normal');
      }
    },
    {
      prop: 'manualShutdownFlag',
      label: 'projectProduct.manualShutdownFlag',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return (row as ProjectProductList).manualShutdownFlag === 1
          ? translation('common.yes')
          : translation('common.no');
      }
    },

    {
      prop: 'createUserName',
      label: 'project.creator',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        return dateFormat((row as ProjectProductList & { createTime: string }).createTime, 'YYYY-MM-DD HH:mm');
      }
    }
  ];

  public selectedRows: Array<ProjectProductList> = [];

  /**
   * 项次id（用于详情页）
   */
  public detailsId = 0;

  /**
   * 控制项次明细dialog的显示隐藏
   */
  public detailsVisible = false;

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      slot: 'start',
      operationType: 'add'
    }
  ];

  /**
   * 查询条件配置
   */
  public queryItemsOption: Array<
    OsQueryItemOption<
      ProjectProductListQuery & {
        productionFlag: number;
        logisticsFlag: number;
        installFlag: number;
        deliveryStatus: number;
        createUserName: string;
        customerId: number;
        installStatus: number;
        invoiceStatus: number;
        archiveStatus: number;
      }
    >
  > = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'prepress.itemType',
      option: {
        placeholder: 'common.select'
      },
      optionData: projectProductService.getItemType
    },
    {
      type: 'Select',
      field: 'attritionFlag',
      label: 'projectProduct.isRemake',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'urgentOrderFlag',
      label: 'order.urgentOrder',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('common.yes'),
            value: 1
          },
          {
            label: translation('common.no'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'invoiceStatus',
      label: 'project.invoiceStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'installStatus',
      label: 'project.installStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'deliveryStatus',
      label: 'order.deliveryStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('project.unfinished'),
            value: 1
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'platformPrepressFlag',
      label: 'projectProduct.platformPrepressFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.make'),
            value: 1
          },
          {
            label: translation('projectProduct.doNotMake'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'makeStatus',
      label: 'projectProduct.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getProductMakeStatusEnum
    },
    {
      type: 'Input',
      field: 'createUserName',
      label: 'project.creator',
      option: {
        placeholder: 'project.creator'
      }
    },
    {
      type: 'Select',
      field: 'prepressStatus',
      label: 'projectProduct.prepressStatus',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getPrepressStatus
    },
    {
      type: 'Select',
      field: 'archiveStatus',
      label: 'projectProduct.archiveStatus',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: projectProductService.getArchiveStatus
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'common.createTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'DateRangePicker',
      field: 'requiredDeliveryTime',
      label: 'projectProduct.shippingDate',
      option: {
        rangeSeparator: '~'
        // pickerOptions: {
        //   disabledDate(callbackDateStr: string): boolean {
        //     const callbackDateTime = new Date(callbackDateStr).getTime();
        //     const today = new Date();
        //     const currentDateTime = today.getTime();
        //     return callbackDateTime >= currentDateTime;
        //   }
        // }
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'order.customer',
      option: {
        placeholder: 'order.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'projectId',
      label: 'billing.ownerProject',
      option: {
        placeholder: 'project.pleaseSelectCustomer',
        clearable: true,
        filterable: true
      },
      optionData: []
    },
    /* {
      type: 'Select',
      field: 'productionFlag',
      label: 'order.production',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    }, */
    {
      type: 'Select',
      field: 'logisticsFlag',
      label: 'order.logisticsFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'installFlag',
      label: 'order.installFlag',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('projectProduct.flagOpen'),
            value: 1
          },
          {
            label: translation('projectProduct.flagClose'),
            value: 0
          }
        ];
      }
    },
    {
      type: 'Select',
      field: 'closeStatus',
      label: 'projectProduct.closeStatus',
      option: {
        placeholder: 'common.select'
      },
      optionData: (): NormalSelectOptions => {
        return [
          {
            label: translation('closeStatus.normal'),
            value: 0
          },
          {
            label: translation('closeStatus.closed'),
            value: 1
          }
        ];
      }
    }
  ];

  /**
   * 展示确稿预览文件
   */
  public showThumbnails = false;

  /**
   * 要预览的图片列表
   */
  public previewImgList: Array<string> = [];

  public importVisible = false;

  public queryForm: Partial<ProjectProductListQuery> = {};

  public defaultQueryForm: Partial<ProjectProductListQuery> = {
    attritionFlag: undefined,
    createTime: [],
    createStartTime: undefined,
    createEndTime: undefined,
    createUserName: '',
    customerId: undefined,
    customerProductName: '',
    deliveryStatus: undefined,
    installFlag: undefined,
    installStatus: undefined,
    invoiceStatus: undefined,
    itemCode: '',
    itemType: undefined,
    logisticsFlag: undefined,
    makeStatus: undefined,
    platformPrepressFlag: undefined,
    platformProductName: '',
    pointName: '',
    prepressStatus: undefined,
    productionFlag: undefined,
    requiredDeliveryEndTime: undefined,
    requiredDeliveryStartTime: undefined,
    requiredDeliveryTime: [],
    urgentOrderFlag: undefined,
    closeStatus: undefined
  };

  private sortOptions: SortOptions<ProjectProductList> = this.tableOption.defaultSort!;

  private readonly disabledConsumeStatus = [ProjectProductStatusEnum.new, ProjectProductStatusEnum.waitForProduction];

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'sale-details-list');
    this.initCustomSelectableList();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.saleDetailsTable as OsTable).clearSelection();
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }
  /**
   * 跳转至项目详情
   */
  public linkToDetails(project: ProjectResource & { projectId: number }): void {
    this.$router.push({
      path: 'project-details',
      query: {
        projectId: project.projectId.toString(),
        code: project.projectCode,
        customerId: project.customerId.toString(),
        customerName: project.customerName
      }
    });
  }

  public linkToLogistics(): void {
    this.detailsId = 0;
    this.detailsVisible = false;
  }

  /**
   * 跳转至项次详情
   */
  public linkToItemDetails(project: ProjectResource): void {
    this.detailsId = project.id;
    this.detailsVisible = true;
  }

  public getCodeColor(projectProduct: ProjectProductList): 'primary' | 'danger' {
    return projectProduct.attritionFlag === 1 ? 'danger' : 'primary';
  }

  public handleSelectionChange(selectedData: Array<ProjectProductList>): void {
    this.selectedRows = selectedData;
  }

  public handleSortChange(sortOptions: SortOptions<ProjectProductList>): void {
    this.sortOptions = sortOptions;
    this.paging.currentPage = 1;
    this.reloadData();
  }

  public batchEditTimeSuccess(): void {
    this.reloadData();
  }

  /**
   * 确稿图片预览
   * @param fileName
   * @returns
   */
  public async thumbnailsPreview(fileName: string): Promise<void> {
    try {
      await minioService.init();
      const url = await minioService.getDownLoadPath(fileName);
      if (getFileExtension(fileName) === 'pdf') {
        const newWindow = window.open();
        newWindow?.document.write(
          `<iframe width="100%" height="100%" src="${url}" frameborder="0" allowfullscreen></iframe>`
        );
        return;
      }
      // 先清空
      this.previewImgList = [];
      this.previewImgList.push(url);
      this.showThumbnails = true;
    } catch (error) {
      messageError(error);
    }
  }

  /**
   * 初始化当前用户可选客户列表
   */
  public async initCustomSelectableList(): Promise<void> {
    const list = await customerService.getAllUsingCustomer();

    const selectableList: Array<any> = [];
    list.forEach(item => {
      selectableList.push({
        label: item.companyName,
        value: item.id
      });
    });

    const option = this.queryItemsOption.find(item => item.field === 'customerId');

    option!.optionData = selectableList;
  }

  public getProjects(): void {
    if (!this.queryForm.customerId) {
      return;
    }
    billingService
      .getProjectsByCustomer(this.queryForm.customerId)
      .then(res => {
        const queryIndex = this.queryItemsOption.findIndex(x => x.field === 'projectId')!;
        this.queryItemsOption[queryIndex].optionData = res.map(x => {
          return {
            label: `[${x.projectCode}] ${x.projectName}`,
            value: x.id
          };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async loadData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const res = await saleDetailsService.getList(this.queryForm, this.paging, this.sortOptions);
      this.tableOption.data = res.data || [];
      this.totalData = res.total || 0;
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProjectProductList>): void {
    const dynamicBtnTypes: Array<string> = ['batchEditTime'];
    this.operationOptions.forEach(x => {
      if (dynamicBtnTypes.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.requiredDeliveryTime')
  private handleDeliveryTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.requiredDeliveryStartTime = undefined;
      this.queryForm.requiredDeliveryEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.requiredDeliveryStartTime = dateFormat(value[0]);
      this.queryForm.requiredDeliveryEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }
  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.customerId')
  private handProjectList(value: number): void {
    if (value > 0) {
      this.getProjects();
    } else {
      this.queryForm.projectId = undefined;
      const queryIndex = this.queryItemsOption.findIndex(x => x.field === 'projectId')!;
      this.queryItemsOption[queryIndex].optionData = [];
    }
  }

  /**
   * 下载确稿文件
   */
  private async downloadConfirmFiles(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const blob = await projectProductService.downloadManuscriptConfirmFile(this.selectedRows.map(x => x.id));
      downloadFileByBlob(`${translation('prepress.manuscriptConfirmFile')}_${dateFormat(new Date())}.zip`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async exportData(): Promise<void> {
    this.tableOption.loading = true;
    try {
      const idList = this.selectedRows.map(x => x.id!);
      const blob = await saleDetailsService.export(this.queryForm as ProjectProductListQuery, idList);
      downloadFileByBlob(`${translation('projectProduct.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }
}
